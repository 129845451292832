<template>
  <component :is="materialData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="materialData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching material data
      </h4>
      <div class="alert-body">
        No material found with this material id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-materials-list'}"
        >
          Material List
        </b-link>
        for other materials.
      </div>
    </b-alert>
    <b-form>
      <b-row> 
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Material Name"
            label-for="material_name"
          >
            <b-form-input
              id="material_name"
              v-model="materialData.material_name"
            />
          </b-form-group>
        </b-col> 
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Remark"
            label-for="material_remark"
          >
            <b-form-textarea
              id="material_remark"
              v-model="materialData.material_remark"
            />
          </b-form-group>
          <b-form-group
              label="Status"
              label-for="status"
            >
                <b-form-select
                  v-model="materialData.material_status"
                  :options="['Active','Inactive']" 
                /> 
 
            </b-form-group>

        </b-col>
       
      </b-row>
    </b-form>
 
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="saveMaterial()"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :to="{ name: 'apps-materials-list'}"
    >
      Cancel
    </b-button>
    
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable,BCardHeader, BCardTitle, BFormCheckbox,BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import materialStoreModule from '../materialStoreModule'

export default {
  components: {
    BFormSelect,
    vSelect,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable, 
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  setup() {
    
    const materialData = ref({}) 
    const statusOptions = ref(["Active","Inactive"]);

    const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-material/fetchMaterial', { id: router.currentRoute.params.id })
      .then(response => { materialData.value = response.data;
      })
      .catch(error => {
        if (error.response.status === 404) {
          materialData.value = undefined
        }
      })

    const saveMaterial = () => {
      let userSessionData = JSON.parse(localStorage.getItem('userData'));
      materialData.value.material_updated_by = userSessionData.user_id;

      store.dispatch('app-material/updateMaterial', materialData.value)
      .then(response => {
        // redirect to list
        alert("Saved!")

       })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      }) 
    }


    return { 
      saveMaterial,
      materialData,
    }
  },
}
</script>

<style>

</style>
