import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {    
     
    fetchMaterials(ctx, queryParams) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/materials', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMaterial(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/getMaterialById?material_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    addMaterial(ctx, materialData) {
      var bodyFormData = new FormData();
      bodyFormData.append('material_name', materialData.material_name);
      bodyFormData.append('material_remark', materialData.material_remark);
      bodyFormData.append('material_unit', materialData.material_unit);
      bodyFormData.append('material_status', materialData.material_status );
      bodyFormData.append('material_created_by', materialData.material_created_by);  
      
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_HOST + '/material',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMaterial(ctx, materialData) {
      var bodyFormData = new FormData();
      bodyFormData.append('material_id', materialData.material_id);
      bodyFormData.append('material_name', materialData.material_name);
      bodyFormData.append('material_remark', materialData.material_remark);
      bodyFormData.append('material_unit', materialData.material_unit);
      bodyFormData.append('material_status', materialData.material_status );
      bodyFormData.append('material_updated_by', materialData.material_updated_by);  
      
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_HOST + '/material',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
